<template>
	<div>
		<div>
			<add-button class="mg-r-10" size="mini" @click.native="toShow" role="tools:update:add"></add-button>
		</div>
		<div class="mg-t-30">
			<div class="pd-t-b-20" style="border-bottom: 1px solid #eee;" v-for="(item,index) in list">
				<div class="cl">
					<div class="f-20 dark-6 z">#{{item.log_time}}</div>
					<div class="y">
						<edit-button size="mini" custom-class="" @click.native="toEdit(item.id)"></edit-button>
						<del-button size="mini" custom-class="" @click.native="toDel(item.id)"></del-button>
					</div>
				</div>
				<div class="f-20 mg-t-10" style="white-space: pre-wrap;">{{item.content}}</div>
			</div>
		</div>
		<el-dialog class="drag-dialog" :title="title" :visible.sync="show" :close-on-click-modal="false" append-to-body @closed="onClosed">
			<el-form
				:model="formData"
				status-icon
				:rules="rules"
				ref="form"
				label-width="auto"
				class="margin-top"
				style="width:800px;"
			>
				<el-form-item label="更新时间" prop="log_time">
					<push-time v-model="formData.log_time" now></push-time>
				</el-form-item>
				<el-form-item label="更新内容" prop="content">
					<el-input v-model="formData.content" style="width:100%;" type="textarea" rows="8"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button @click="onClosed">关闭</el-button>
					<el-button type="primary" @click="toSave">保存</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "update",
	data() {
		return {
			list:[],
			title:'',
			show:false,
			formData:{
				id:'',
				content:'',
				log_time:''
			},
			rules:{
				content:[{required:true,message:'更新内容必填'}],
				log_time:[{required:true,message:'更新时间必填'}]
			}
		}
	},
	created() {
	},
	mounted() {
		this.getList();
	},
	methods: {
		toShow(){
			this.show = true;
			this.title = '添加更新日志';
		},
		onClosed(){
			this.formData.id = '';
			this.formData.content = '';
			this.formData.log_time = '';
			this.show = false;
		},
		toSave(){
			let _this = this;

			if(this.formData.id){
				this.$http.request({
					url: '/UpdateLog/edit',
					datas: this.formData,
					success(res) {
						_this.$utils.success("保存成功！");
						_this.getList();
						_this.show = false;
					}
				})
			}else {
				let _datas = JSON.parse(JSON.stringify(this.formData))

				delete _datas.id;
				this.$http.request({
					url: '/UpdateLog/add',
					datas: _datas,
					success(res) {
						_this.$utils.success("保存成功！");
						_this.getList();
						_this.show = false;
					}
				})
			}
		},
		getList(){
			let _this = this;

			this.$http.request({
				url:'/UpdateLog/listAll',
				datas:{state:1,orderBy:'log_time',sortBy:'desc'},
				success(res){
					_this.list = res;
				}
			})
		},
		toEdit(id){
			let _this = this;

			this.$http.request({
				url:'/UpdateLog/detail',
				datas:{id:id},
				success(res){
					_this.formData.id = res.id;
					_this.formData.content = res.content;
					_this.formData.log_time = res.log_time;
					_this.title = '编辑更新日志';
					_this.show = true;
				}
			})
		},
		toDel(id){
			let _this = this;
			this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				this.$http.request({
					url: '/UpdateLog/del',
					datas: {id:id},
					success(res) {
						_this.$utils.success("删除成功！");
						_this.getList();
					}
				});
			}).catch(() => {});
		}
	}
}
</script>
